import React from 'react';
import { useLocation, useParams } from 'react-router-dom'; 
import formatNumber from '../utils/format'; // Explicit extension if needed


const AudienceDetail = () => {
  const { audience } = useParams(); // Get the audience name from the URL
  const location = useLocation(); // Get the passed state (subreddit data)

  // Load data from state (passed from Dashboard) or from localStorage
  const subreddits = location.state?.subreddit_name_prefixed || JSON.parse(localStorage.getItem(audience));

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-[#1A2238] text-white">
      <h1 className="text-2xl font-bold mb-4">Subreddits for {audience}</h1>
      <div className="container mx-auto p-4 bg-white shadow-md rounded-lg text-black">
        <ul>
          {subreddits.map((subreddit, index) => (
            <li key={index} className="mb-6 flex items-center justify-between">
              <div className="flex items-center">
                <img
                  src={subreddit.subreddit_icon_img}
                  alt={`${subreddit.subreddit} icon`}
                  className="h-10 w-10 rounded-full mr-4"
                />
                <div>
                  <h2 className="text-lg font-bold">{subreddit.subreddit_name_prefixed}</h2>
                  <p className="text-sm">Subscribers: {formatNumber(subreddit.subscribers)}</p>
                </div>
              </div>
              <a
                href={`https://www.reddit.com/r/${subreddit.subreddit}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-indigo-600 hover:text-pink-500"
              >
                Visit Subreddit
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default AudienceDetail;
