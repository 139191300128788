function formatNumber(number) {
    if (typeof number !== 'number' || isNaN(number)) {
      console.error('Invalid number:', number);
      return '0';
    }
    if (number >= 1_000_000_000) {
      return (number / 1_000_000_000).toFixed(1).replace(/\.0$/, '') + 'B';
    }
    if (number >= 1_000_000) {
      return (number / 1_000_000).toFixed(1).replace(/\.0$/, '') + 'M';
    }
    if (number >= 1_000) {
      return (number / 1_000).toFixed(1).replace(/\.0$/, '') + 'K';
    }
    return number.toString();
  }

  module.exports = formatNumber;