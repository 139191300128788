import React from 'react';

const TermsAndConditions = () => {
    return (
        <div className="container mx-auto p-6 text-gray-800">
            <h1 className="text-3xl font-bold mb-4">Terms and Conditions</h1>
            <p className="mb-4">
                {/* Add the content of your terms and conditions here */}
                Welcome to ProblemSpotter! These terms and conditions outline the rules and regulations for the use of ProblemSpotter's Website.
            </p>
            {/* Add more terms and conditions content here */}
        </div>
    );
};

export default TermsAndConditions;
