import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Spinner from './Spinner';
import formatNumber from '../utils/format';

const Dashboard = () => {
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  const initialAudienceData = {
    'Startup Founders': [],
    'Fitness': [],
  };

  const [audienceData, setAudienceData] = useState(initialAudienceData);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const saveToLocalStorage = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  };

  const loadFromLocalStorage = (key) => {
    const storedData = localStorage.getItem(key);
    return storedData ? JSON.parse(storedData) : null;
  };

  const clearCache = () => {
    Object.keys(localStorage).forEach((key) => {
      if (key.includes('audience') || key.includes('subreddit')) {
        localStorage.removeItem(key);
      }
    });
    sessionStorage.removeItem("visited");
  };

  const decodeUrl = (url) => {
    return url ? url.replace(/&amp;/g, '&') : null;
  };

  const getValidIconUrl = (subreddit) => {
    const iconUrl = subreddit.subreddit_icon_img || subreddit.community_icon;
    return decodeUrl(iconUrl) || 'default-icon.png';  // Fallback to default image if no icon is available
  };

  const searchRedditByAudience = async (audience) => {
    const cachedData = loadFromLocalStorage(audience);

    // if (cachedData) {
    //   setAudienceData((prevData) => ({
    //     ...prevData,
    //     [audience]: cachedData,
    //   }));
    //   return;
    // }

    setLoading(true);
    setError('');
    try {
      const response = await fetch(`${baseUrl}/v1/search-subreddits?q=${audience}&limit=10`);
      if (!response.ok) {
        throw new Error('Failed to fetch search results');
      }

      let data = await response.json();

      // Remove duplicates based on `subreddit_name_prefixed`
      const uniqueSubreddits = new Map();
      data.forEach((subreddit) => {
        if (!uniqueSubreddits.has(subreddit.subreddit_name_prefixed)) {
          uniqueSubreddits.set(subreddit.subreddit_name_prefixed, subreddit);
        }
      });
      data = Array.from(uniqueSubreddits.values());

      setAudienceData((prevData) => ({
        ...prevData,
        [audience]: data,
      }));

      saveToLocalStorage(audience, data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // if (!window._cacheCleared) {
    //   clearCache();
    //   window._cacheCleared = true;
    // }
  }, []);

  useEffect(() => {
    const audiences = Object.keys(audienceData);
    audiences.forEach((audience) => {
      searchRedditByAudience(audience);
    });
  }, []);

  return (
    <main className="flex-grow flex flex-col items-center justify-center bg-white">
      {loading && <Spinner />}
      
      <div className="container mx-auto p-4 shadow-md rounded grid grid-cols-1 md:grid-cols-2 gap-4" style={{ maxHeight: '80vh' }}>
        {Object.entries(audienceData).map(([audience, subreddits]) => {
          const totalAudience = subreddits.reduce((acc, subreddit) => acc + subreddit.subscribers, 0);

          return (
            <div key={audience} className="bg-white p-4 shadow-lg rounded-lg flex flex-col items-center max-h-full">
              <h2 className="text-lg font-bold mb-2 text-black text-center">{audience}</h2>
              <p className="text-sm text-gray-600 mb-4 text-center">
                Total Audience: {formatNumber(totalAudience)} subscribers
              </p>

              {subreddits.length > 0 ? (
                <Link to={`/audience/${audience}`} state={{ subreddits }} className="w-full">
                  <div className="grid grid-cols-5 gap-2">
                    {subreddits.slice(0, 10).map((subreddit, index) => (
                      <div key={index} className="flex flex-col items-center group">
                        <img
                          src={getValidIconUrl(subreddit)}
                          alt={`${subreddit.subreddit_name_prefixed} icon`}
                          className="h-10 w-10 rounded-full object-contain"  // Use object-contain to handle different icon sizes
                        />
                        <p className="text-sm text-gray-700 text-center mt-2 hidden group-hover:block">
                          {subreddit.subreddit_name_prefixed}
                        </p>
                      </div>
                    ))}
                  </div>
                </Link>
              ) : (
                <p className="text-gray-500 text-center">No data available</p>
              )}
            </div>
          );
        })}
      </div>
    </main>
  );
};

export default Dashboard;
