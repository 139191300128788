export const pricingPlans = [
    {
        title: 'Free',
        priceId: '0',
        features: [
            '50 searches per month',
            'AI suggestions',
        ],
        isPopular: false,
    },
    
    {
        title: 'Pro',
        priceId: 'price_1PnoMNGPFt6f57QGTzXmLVrg',
        features: [
            '100 searches per month',
            'AI suggestions',
            'Subreddit trends so that you can find the 💡 that you\'re looking for',
        ],
        isPopular: true,
    },
    {
        title: 'Enterprise',
        priceId: '69',
        features: [
            '200 searches per month',
            'AI suggestions',
            'Subreddit trends so that you can find the 💡 that you\'re looking for',
            'Auto search for you with results the next day',
            'Idea salad for features that you never thought are possible',
        ],
        isPopular: false,
    }
];
